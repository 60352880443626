var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lists-page__none",
    class: {
      small: _vm.small
    }
  }, [!_vm.small ? _c('div', {
    staticClass: "lists-page__none-title"
  }, [_vm._v(" Мониторинг РРЦ для Ozon и Wildberries ")]) : _vm._e(), _vm.small ? _c('img', {
    staticClass: "loading__img",
    attrs: {
      "src": require('@/assets/images/wireframes/PriceVar.png')
    }
  }) : _vm._e(), _vm.small ? _c('div', {
    staticClass: "lists-page__none-text",
    class: {
      small: _vm.small
    }
  }, [_vm._v(" " + _vm._s(this.text) + " ")]) : _c('div', {
    staticClass: "lists-page__none-text"
  }, [_vm._v(" В этом разделе вы можете настроить регулярный мониторинг рекомендованной розничной цены (РРЦ) по вашему списку товаров на маркетплейсах Ozon и Wildberries. "), _c('br'), _c('br'), _vm._v(" Если сервис зафиксирует отклонение от вашей РРЦ на маркетплейсе, вам сразу поступит уведомление. ")]), _c('button', {
    staticClass: "btn-outline btn-width-auto",
    class: {
      'connect-btn': _vm.small
    },
    on: {
      "click": _vm.onCreateList
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`)
    }
  }), _vm._v(" Настроить первый мониторинг РРЦ ")]), _c('modal-add-PriceVar', {
    ref: "modalAddPriceVar",
    on: {
      "complete": _vm.onAdded
    }
  }), _c('modal-PriceVar-import', {
    ref: "modalPriceVarImport",
    on: {
      "complete": function ($event) {
        _vm.refreshKey++;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };