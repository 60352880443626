var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('price-var-list-wrapper', {
    attrs: {
      "hasItems": _vm.hasItems,
      "hasItemsLoaded": _vm.hasItemsLoaded
    }
  }, [_c('div', {
    staticClass: "lists-page"
  }, [_c('div', {
    staticClass: "lists-page__content"
  }, [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_vm.limitRrc ? _c('div', {
          staticStyle: {
            "margin-top": "10px",
            "margin-right": "20px"
          }
        }, [_c('span', {
          staticClass: "limit"
        }, [_vm._v("Обновлений цен за сегодня:")]), _c('span', {
          staticClass: "limit"
        }, [_vm._v(" " + _vm._s(_vm.limitRrc.count) + " из " + _vm._s(_vm.limitRrc.limit) + " доступных ")])]) : _vm._e(), _c('button', {
          staticClass: "btn-outline btn-width-auto",
          staticStyle: {
            "margin-left": "20px"
          },
          on: {
            "click": _vm.onCreateList
          }
        }, [_c('img', {
          attrs: {
            "src": require(`@/assets/images/icons/plus.svg`)
          }
        }), _vm._v(" Новый список РРЦ ")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" Мои списки РРЦ ")]), _c('price-var-list-table', {
    key: _vm.tableKey,
    ref: "table",
    staticClass: "mt32",
    on: {
      "edit": _vm.onEdit,
      "remove": _vm.onRemove
    }
  })], 1), _c('modal-add-price-var', {
    ref: "modalAddPriceVar",
    on: {
      "complete": _vm.onAdded
    }
  }), _c('modal-update-price-var', {
    ref: "modalUpdatePriceVar"
  }), _c('modal-remove-price-var', {
    ref: "modalRemovePriceVar",
    on: {
      "complete": function ($event) {
        _vm.refreshKey++;
      }
    }
  }), _c('modal-price-var-import', {
    ref: "modalPriceVarImport",
    on: {
      "complete": function ($event) {
        _vm.refreshKey++;
      }
    }
  }), _c('modal-limit-reached-template', {
    ref: "ModalLimitReachedTemplate"
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };