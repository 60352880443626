import "core-js/modules/es.array.push.js";
import ModalAddPriceVar from "@/components/modals/ModalAddPriceVar.vue";
import ModalUpdatePriceVar from "@/components/modals/ModalUpdatePriceVar.vue";
import ModalRemovePriceVar from "@/components/modals/ModalRemovePriceVar.vue";
import ModalPriceVarImport from "@/components/modals/ModalPriceVarImport.vue";
import MainTitle from "@/components/MainTitle.vue";
import PriceVarListTable from "@/components/PriceVar/PriceVarListTable.vue";
import ModalLimitReachedTemplate from "@/components/modals/access/ModalLimitReachedTemplate.vue";
import PriceVarListWrapper from "@/components/PriceVar/Wrapper.vue";
export default {
  meta: {
    title: "Мои списки Мониторинга РРЦ"
  },
  data() {
    return {
      refreshKey: 0,
      //hasItems: this.$store.state.user.user ? null : false,
      //PriceVarListTotalRow: null,

      hasItems: false,
      hasItemsLoaded: false,
      limitRrc: null
    };
  },
  async created() {
    await this.loadRrcLimits();

    //console.log("107: -> dispatch(priceVar/getPriceVarList)");
    const result = await this.$store.dispatch("priceVar/getPriceVarList", {
      page: 1
    });
    //console.log("111: result=", result);

    //this.hasItems = !! result?.items?.length;
    this.hasItems = (result === null || result === void 0 ? void 0 : result.total) > 0 ? true : false;
    //console.log("114: this.hasItems=", this.hasItems);

    this.hasItemsLoaded = true;
  },
  methods: {
    onEdit({
      item,
      context
    }) {
      //console.log("pVL.item=", item);

      this.$refs.modalUpdatePriceVar.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    async loadRrcLimits() {
      this.limitRrc = await this.$store.dispatch("priceVar/getRrcLimit", {
        fake: this.$route.params.id
      });
    },
    /*
     onRemove({ item, context}){
        this.$refs.modalRemovePriceVar.open({
            item,
            onComplete() {
                this.refreshKey++;
                 //console.log("145: ->context.refresh()")
                context.refresh();
                 //console.log("148: ->this.refs.table.refresh()")
                this.$refs.table.refresh();
                //console.log("150:")
            },
        });
    },
    */
    onRemove({
      item,
      context
    }) {
      this.$refs.modalRemovePriceVar.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    onAdded(item) {
      this.refreshKey++;
      this.hasItems = true;

      //this.$router.push({ name: "priceVar", params: { id: item.id } });
      this.$router.push({
        name: "retail_price_monitoring",
        params: {
          id: item.id
        }
      });

      // setTimeout(() => {
      //     this.$refs.modalPriceVarImport.open({
      //         item,
      //         freshList: true,
      //     });
      // }, 100);
    },
    onCreateList() {
      this.$access.hasOrThrow("priceVarList");

      /*
      const monitorsLimit = this.$access.getLimit("monitors");
       // colsole.log('src/views/PriceVars.vue: monitorsLimit='+monitorsLimit)
      // colsole.log('src/views/PriceVars.vue: this.PriceVarListTotalRow='+this.PriceVarListTotalRow)
       if (this.PriceVarListTotalRow >= monitorsLimit) {
          this.$modal.open(
              () =>
                  import(
                      "@/components/modals/access/ModalLimitReachedTemplate"
                  ),
              {
                  alert: "Исчерпан лимит мониторингов",
                  title: "Исчерпан лимит мониторингов на Вашем тарифе",
              }
          );
      } else {
      */
      this.$refs.modalAddPriceVar.open();
      //}
    }

    /*
    // onPriceVarsTotalRow(total) {
    //     alert('111: total='+total)
    //     this.PriceVarsTotalRow = total
    // }
    */
  },
  computed: {
    tableKey() {
      return this.refreshKey;
    }
  },
  components: {
    ModalAddPriceVar,
    ModalUpdatePriceVar,
    ModalRemovePriceVar,
    ModalPriceVarImport,
    MainTitle,
    PriceVarListTable,
    ModalLimitReachedTemplate,
    PriceVarListWrapper
  }
};