import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import AppSelect from "@/components/AppSelect.vue";
import { getRrcMonitorArray, getMarketplacesListArray, getRrcScheduleArray, getRrcPriceTypeArray } from "@/utils/enums";
import Tooltip from "@/components/Tooltip";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    }
  },
  data() {
    const select_monitor_items = [...getRrcMonitorArray()];
    const select_marketplace_items = [...getMarketplacesListArray()];
    const select_shedule_items = [...getRrcScheduleArray()];
    let select_entity_items_wb = [...getRrcPriceTypeArray('wb')];
    let select_entity_items_ozon = [...getRrcPriceTypeArray('ozon')];

    //console.log("170: select_marketplace_items=", select_marketplace_items);
    //console.log("171: select_entity_items_wb=", select_entity_items_wb);

    //let select_entity_items = [];
    //let select_monitor_items = [];

    /*
    // Разделяем на 2 массива
    select_type_items.forEach((item) => {
        [
            "product",
            //, "brand", "seller", "category"
        ].forEach((el) => {
            if (item.id.indexOf(el) === 0) {
                select_entity_items.push({
                    id: el,
                    title: item.title.split(":")[0],
                });
                //} else {
                select_monitor_items.push({
                    id: item.id,
                    title: item.title.split(":")[1],
                });
            }
        });
    });
    */

    //console.log("select_entity_items=", select_entity_items);
    //console.log("select_monitor_items=", select_monitor_items);

    // Удаляем дубликаты
    //select_entity_items = this.removeDuplicates(select_entity_items);
    //select_monitor_items = this.removeDuplicates(select_monitor_items);

    const initial_new_list = {
      title: "",
      entity: select_entity_items_wb[0],
      monitor: select_monitor_items[0],
      mp: select_marketplace_items[0],
      description: "",
      variation: "",
      shedule: select_shedule_items[0]
    };
    return {
      initial_new_list,
      new_list: {
        ...initial_new_list
      },
      entity: null,
      //mp: null,
      mp: null,
      //select_type_items,
      select_entity_items_wb,
      select_entity_items_ozon,
      select_monitor_items,
      select_marketplace_items,
      select_shedule_items,
      loading: false,
      variationError: false,
      variationTouched: false
    };
  },
  mounted() {},
  methods: {
    onOpen({
      entity = null,
      mp = null
    } = {}) {// eslint-disable-line
      //console.log('233: entity=',entity, 'mp=',mp);

      /*
      this.mp = mp;
      if (mp) {
          //console.log('235: mp=',mp);
           this.new_list.mp = this.select_marketplace_items.find(
              (i) => i.id === mp
          );
      }
       this.new_list = { ...this.initial_new_list };
      this.entity = entity;
      if (entity) {
          this.new_list.entity = this.select_entity_items.find(
              (i) => i.id === entity
          );
      }
      */
    },
    /*
    removeDuplicates(array) {
        // Declare a new array
        let newArray = [];
         // Declare an empty object
        let uniqueObject = {};
         // Loop for the array elements
        for (let i in array) {
            // Extract the title
            let objTitle = array[i]["id"];
             // Use the title as the index
            uniqueObject[objTitle] = array[i];
        }
         // Loop to push unique object into array
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }
        return newArray;
    },
    */
    async submit({
      close
    }) {
      this.loading = true;
      try {
        var _this$new_list$monito, _this$new_list$entity, _this$new_list$mp;
        const monitor_id = (_this$new_list$monito = this.new_list.monitor) === null || _this$new_list$monito === void 0 ? void 0 : _this$new_list$monito.id;
        const entity_id = (_this$new_list$entity = this.new_list.entity) === null || _this$new_list$entity === void 0 ? void 0 : _this$new_list$entity.id;

        // let entity_id;
        // ["product", "brand", "seller", "category"].forEach((el) => {
        //     if (monitor_id.indexOf(el) === 0) entity_id = el;
        // });

        //console.log("monitor_id=" + monitor_id);
        //console.log("entity_id=" + entity_id);

        const result = await this.$store.dispatch("priceVar/addPriceVar", {
          title: this.new_list.title,
          mp: (_this$new_list$mp = this.new_list.mp) === null || _this$new_list$mp === void 0 ? void 0 : _this$new_list$mp.id,
          entity: entity_id,
          monitor: monitor_id,
          variation: this.new_list.variation,
          description: this.new_list.description,
          shedule: this.new_list.shedule.id
        });
        this.loading = false;
        close();
        this.$emit("complete", result);
      } catch (e) {
        this.loading = false;
      }
    },
    isValidNumberString(str) {
      // Check if the string is not empty
      if (str.length < 2) return false;

      // Check if the first character is either '+' or '-'
      if (str[0] !== "+" && str[0] !== "-") return false;

      // Check if the rest of the characters are all numbers
      for (let i = 1; i < str.length; i++) {
        if (!/\d/.test(str[i])) {
          return false;
        }
      }

      // If all conditions pass, return true
      return true;
    }
  },
  computed: {
    // Значения второго селекта в зависимости от первого
    current_select_monitor_items() {
      /*
      return this.select_monitor_items.filter((item) =>
          item.id.startsWith(this.new_list.entity?.id)
      );
      */
      return this.select_monitor_items;
    },
    showVariation() {
      return this.new_list.monitor.id === "productPriceVarPercent" || this.new_list.monitor.id === "productPriceVarRub";
    },
    variationValid() {
      if (this.showVariation) {
        return this.isValidNumberString(this.new_list.variation);
      } else {
        return true;
      }
    },
    valid() {
      var _this$new_list$title, _this$new_list$entity2, _this$new_list$mp2;
      return ((_this$new_list$title = this.new_list.title) === null || _this$new_list$title === void 0 ? void 0 : _this$new_list$title.length) && ((_this$new_list$entity2 = this.new_list.entity) === null || _this$new_list$entity2 === void 0 ? void 0 : _this$new_list$entity2.id) && ((_this$new_list$mp2 = this.new_list.mp) === null || _this$new_list$mp2 === void 0 ? void 0 : _this$new_list$mp2.id) && this.variationValid;
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Далее",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  watch: {
    // Выставляем значение по умолчанию при переключении первого селекта
    current_select_monitor_items(value) {
      this.new_list.monitor = value[0];
    },
    "new_list.mp.id": function (new_list_mp_id) {
      this.new_list.entity = new_list_mp_id == 'wb' ? this.select_entity_items_wb[0] : this.select_entity_items_ozon[0];
    }
  },
  components: {
    Modal,
    AppSelect,
    Tooltip
  }
};