import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
import {
//getRrcPriceType,
getRrcMonitor, getRrcSchedule, getMarketplacesList } from "@/utils/enums";
import dayjs from "dayjs";
export default {
  components: {
    DataTable
  },
  props: {
    configurable: {
      type: Boolean,
      default: false
    },
    paginatable: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    async tableLoader({
      query
    }) {
      const result = await this.$store.dispatch("priceVar/getPriceVarList", query);

      // if (query.page && query.page === 1) {
      //     this.$emit("update:hasItems", !!result.items.length);
      // }

      ////alert('src/components/PriceVar/PriceVarListTable.vue: result.total='+JSON.stringify(result.total))

      //this.$emit("update:PriceVarListTotalRow", result.total);

      return result;
    }
  },
  computed: {
    noData() {
      return `<center>Ваш список мониторингов РРЦ пуст.<br>
                        Для наполнения списка воспользуйтесь кнопкой <b>Новый список РРЦ</b>
                    </center>`;
    },
    now() {
      let dt = dayjs().format('YYYY-MM-DD');
      //console.log('74: now=', dt)
      return dt;
    },
    yesterday() {
      let dt = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
      //console.log('74: yesterday=', dt)
      return dt;
    },
    columns() {
      let columns = [{
        title: "Название",
        name: "title",
        width: 250,
        type: "link",
        getLink(item) {
          return {
            route: {
              //name: "priceVar",
              name: "retail_price_monitoring",
              params: {
                id: item.id
              }
            },
            text: item.title,
            target: "_blank"
          };
        }
      }, {
        title: "Дата создания",
        name: "date",
        width: 120,
        type: "date"
      }, {
        title: "Последнее отклонение",
        //name: "date_last_notify_string",
        name: "date_last_notify",
        width: 120,
        //type: "text",
        type: "slot"
      },
      /*
      {
          title: "Тип цены",
          name: "entity",
              //getText: (v) => getRrcPriceType()[v],
          width: 160,
      },
      */
      {
        title: "Тип отклонения",
        name: "monitor",
        //getText: (v) => getMonitorPriceVar2()[v],
        getText: v => getRrcMonitor()[v],
        width: 200
      }, {
        title: "Максимальное отклонение",
        name: "variation",
        type: "text",
        width: 80,
        filter: "numberRange"
      }, {
        title: "Маркетплейс",
        name: "mp",
        getText: v => getMarketplacesList()[v],
        width: 120
      }, {
        title: "Кол-во",
        name: "count",
        width: 100
      }, {
        title: "Частота обновления",
        name: "shedule",
        getText: v => getRrcSchedule()[v],
        width: 160
      }, {
        title: "Последнее обновление",
        name: "date_last_fresh",
        type: "text",
        width: 160
      }, {
        title: "Следующее обновление",
        name: "date_next_shedule",
        type: "text",
        width: 160
      }, {
        title: "Описание",
        name: "description",
        width: 200
      }, {
        title: "",
        name: "_actions",
        type: "actions",
        actions: [{
          title: "Удалить",
          action: (item, {
            refresh
          }) => {
            this.$emit("remove", {
              item,
              context: {
                refresh
              }
            });
          }
        }, {
          title: "Редактировать",
          action: (item, {
            refresh
          }) => {
            this.$emit("edit", {
              item,
              context: {
                refresh
              }
            });
          }
        }],
        width: 60
      }].map((it, i) => {
        it.show = true;
        it.position = i + 1;
        return it;
      });
      if (this.small) {
        columns = columns.filter(column => {
          return column.name === "title" || column.name === "date_last_notify" || column.name === "monitor";
        });
      }
      return columns;
    }
  }
};