var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Редактирование списка РРЦ",
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Название")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_list.title,
      expression: "new_list.title"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Название списка",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.new_list.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_list, "title", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Параметры отслеживания РРЦ "), _c('Tooltip', {
    attrs: {
      "position": "top"
    }
  }, [_vm._v(" Выберите, какой тип отклонения от указанной РРЦ будет отслеживать сервис. Вы можете изменить эту настройку позже. ")])], 1), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "1fr 1fr",
      "column-gap": "10px"
    }
  }, [_c('app-select', {
    attrs: {
      "items": _vm.select_monitor_items,
      "fullwidth": "",
      "width": 300
    },
    model: {
      value: _vm.new_list.monitor,
      callback: function ($$v) {
        _vm.$set(_vm.new_list, "monitor", $$v);
      },
      expression: "new_list.monitor"
    }
  })], 1)]), _vm.showVariation ? _c('div', {
    staticClass: "modal-item",
    class: {
      error: !_vm.variationValid && _vm.variationTouched
    }
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Максимально допустимое отклонение " + _vm._s(_vm.new_list.monitor.id === "productPriceVarPercent" ? "в %" : "в руб.") + " "), _c('Tooltip', {
    attrs: {
      "position": "top"
    }
  }, [_vm._v(" Введите максимально допустимый размер отклонения (разницу от РРЦ в ту или иную сторону), который будет отслеживать сервис. Если мы обнаружим отклонение больше этого значения - вам поступит уведомление. Вы можете изменить эту настройку позже. ")])], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_list.variation,
      expression: "new_list.variation"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Например -10 или +20"
    },
    domProps: {
      "value": _vm.new_list.variation
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_list, "variation", $event.target.value);
      }, function ($event) {
        _vm.variationTouched = true;
      }]
    }
  }), !_vm.variationValid && _vm.variationTouched ? _c('span', {
    staticClass: "error-text"
  }, [_vm._v(" Неверный формат ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Частота мониторинга "), _c('Tooltip', {
    attrs: {
      "position": "top"
    }
  }, [_vm._v(" Укажите периодичность, с которой сервис будет проверять цену по вашему списку товаров. Вы можете изменить эту настройку позже. ")])], 1), _c('app-select', {
    attrs: {
      "items": _vm.select_shedule_items,
      "fullwidth": "",
      "width": 300
    },
    model: {
      value: _vm.new_list.shedule,
      callback: function ($$v) {
        _vm.$set(_vm.new_list, "shedule", $$v);
      },
      expression: "new_list.shedule"
    }
  })], 1), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Описание")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_list.description,
      expression: "new_list.description"
    }],
    attrs: {
      "placeholder": "Введите описание (опционально)"
    },
    domProps: {
      "value": _vm.new_list.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_list, "description", $event.target.value);
      }
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };