var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', {
    ref: "table",
    attrs: {
      "table-id": "PriceVarList",
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "noData": _vm.noData,
      "item-id": "id",
      "paginatable": _vm.paginatable,
      "configurable": _vm.configurable,
      "persist-settings": false,
      "selectable": false,
      "sticky-columns": _vm.small ? 0 : 1
    },
    scopedSlots: _vm._u([{
      key: "date_last_notify",
      fn: function ({
        item
      }) {
        return [item.date_last_notify == _vm.now ? _c('span', {
          staticClass: "monGreen"
        }, [_vm._v(" Сегодня ")]) : item.date_last_notify == _vm.yesterday ? _c('span', {
          staticClass: "monGreen"
        }, [_vm._v(" Вчера ")]) : _c('span', [_vm._v(" " + _vm._s(item.date_last_notify) + " ")])];
      }
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };